import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useLogout from '@/hooks/useLogout'
import Text from '@/utils/Text'
import Button from '@/components/buttons/Button'
import * as m from '@/utils/messages'
import { navigate } from 'gatsby'
import '@/style/main.css'
import withAuth from '@/utils/withAuth'

const LogoutPage = () => {
    const { isOneHubIntegrationActive, oneHubLogoutUrl, ssoLogoutUrl } = useSelector(state => state.user)
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [logoutAttempted, setLogoutAttempted] = useState(false)
    const logout = useLogout()

    useEffect(() => {
        if (logoutAttempted) return;

        const handleLogout = async () => {
            setLogoutAttempted(true);

            try {
                await logout()

                window.localStorage.removeItem("loggedIn")
                window.localStorage.removeItem("user")
                window.localStorage.removeItem("loginCredentials")

                if (ssoLogoutUrl) {
                    window.location.href = ssoLogoutUrl;
                } else {
                    navigate('/logout-success')
                    setIsLoading(false)
                }

            } catch (err) {
                setError(m.LOGOUT_ERROR)
                setIsLoading(false)
            }
        }

        handleLogout()
    }, [logout, isOneHubIntegrationActive, oneHubLogoutUrl, ssoLogoutUrl, logoutAttempted])

    const handleRetry = () => {
        setError(null)
        setLogoutAttempted(false)
        setIsLoading(true)
    }

    if (isLoading) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen">
                <div className="text-center">
                    <Text as="h1" className="text-base font-h-light mb-lg text-center">
                        Logging out...
                    </Text>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
            <div className="text-center">
                <Text
                    as="h1"
                    className={`text-base font-h-light mb-lg text-center ${error ? 'text-error' : ''}`}
                >
                    {error}
                </Text>
                <div className="flex justify-center">
                    {error && (
                        <Button
                            onClick={handleRetry}
                            theme="secondary"
                            className="mr-lg"
                        >
                            {m.RETRY}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default withAuth(LogoutPage)