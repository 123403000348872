import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { getPersistor } from '@rematch/persist'
import useProgressBar from '@/hooks/useProgressBar'
import fetchApi from '@/utils/fetchApi'
import * as restAPI from '@/utils/endpoints'

function useLogout() {
	const dispatch = useDispatch()
	const { setLoading } = useProgressBar()

	return useCallback(
		async (isError = false) => {
			if (!isError) {
				setLoading(true)

				try {
					await fetchApi(restAPI.V2_LOGOUT)
					await fetchApi(restAPI.BEAM_LOGOUT, {
						method: "DELETE"
					})

					dispatch({
						type: 'user/LOGOUT'
					})

					/*
					* We have to pause the persistor here, otherwise when the navigation to the homepage happens, the
					* persist library will get confused and will not retrieve the state correctly due the reset that takes place.
					*/
					getPersistor().pause();

					dispatch({
						type: 'RESET_APP'
					})

					getPersistor().persist();
				} finally {
					setLoading(false)
				}
			}
		},
		[dispatch, setLoading]
	)
}

export default useLogout